import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db, auth } from './config';
import { User } from '../types/user';

// Admin credentials
const ADMIN_CREDENTIALS = {
  email: 'glocalnews.app@gmail.com',
  phone: '+919916666560',
  password: 'Blackerz@5793'
};

export async function verifyAdminCredentials(identifier: string, password: string): Promise<boolean> {
  try {
    await setPersistence(auth, browserLocalPersistence);

    // Check if using admin credentials
    if (identifier === ADMIN_CREDENTIALS.email || identifier === ADMIN_CREDENTIALS.phone) {
      if (password !== ADMIN_CREDENTIALS.password) {
        return false;
      }
      identifier = ADMIN_CREDENTIALS.email;
    }

    const userCredential = await signInWithEmailAndPassword(auth, identifier, password);
    const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));

    if (!userDoc.exists()) {
      await auth.signOut();
      return false;
    }

    const userData = userDoc.data() as User;
    if (userData.role !== 'admin' && userData.role !== 'super_admin') {
      await auth.signOut();
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error verifying admin credentials:', error);
    return false;
  }
}

export async function createAdminUser(email: string, phone: string, name: string): Promise<string> {
  try {
    const userDoc = doc(db, 'users', email);
    const adminUser: Omit<User, 'id'> = {
      email,
      phoneNumber: phone,
      displayName: name,
      role: 'admin',
      languages: ['en'],
      regions: [],
      createdAt: new Date().toISOString(),
      lastLogin: new Date().toISOString(),
      status: 'active'
    };

    await setDoc(userDoc, adminUser);
    return userDoc.id;
  } catch (error) {
    console.error('Error creating admin user:', error);
    throw error;
  }
}