import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LiveNewsChannels from '../components/youtube/LiveNewsChannels';
import ExternalLiveChannels from '../components/youtube/ExternalLiveChannels';
import LiveTVModal from '../components/youtube/LiveTVModal';
import { sampleLiveChannels } from '../data/sampleLiveChannels';

export default function LiveTV() {
  const { t } = useTranslation();
  const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {/* Glocal Live */}
      <section>
        <h2 className="text-2xl font-bold mb-6">Glocal Live</h2>
        <div className="bg-gray-100 p-8 rounded-lg text-center">
          <p className="text-gray-600">Glocal News live channels will appear here once added through the admin panel.</p>
        </div>
      </section>

      {/* Live News Channels */}
      <section className="mt-12">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
          <h2 className="text-2xl font-bold">Live News</h2>
          
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
            <input
              type="search"
              placeholder="Search channels..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="px-4 py-2 border rounded-md focus:ring-red-500 focus:border-red-500"
            />
            
            <select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              className="px-4 py-2 border rounded-md focus:ring-red-500 focus:border-red-500"
            >
              <option value="all">All Languages</option>
              <option value="telugu">Telugu</option>
              <option value="kannada">Kannada</option>
              <option value="tamil">Tamil</option>
            </select>
          </div>
        </div>

        <ExternalLiveChannels
          channels={sampleLiveChannels}
          language={selectedLanguage}
          searchQuery={searchQuery}
          onChannelSelect={setSelectedChannel}
        />
      </section>

      {/* Live TV Modal */}
      <LiveTVModal
        channelId={selectedChannel}
        isOpen={!!selectedChannel}
        onClose={() => setSelectedChannel(null)}
      />
    </div>
  );
}