import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  channelId: string | null;
  isOpen: boolean;
  onClose: () => void;
}

export default function LiveTVModal({ channelId, isOpen, onClose }: Props) {
  if (!channelId) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-4xl bg-black rounded-lg shadow-xl">
          <div className="relative">
            <button
              onClick={onClose}
              className="absolute -top-10 right-0 text-white hover:text-gray-300"
            >
              <XMarkIcon className="h-8 w-8" />
            </button>

            <div className="aspect-video">
              <iframe
                src={`https://www.youtube.com/embed/live_stream?channel=${channelId}&autoplay=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full rounded-lg"
              />
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}