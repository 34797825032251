import { useEffect, useState } from 'react';
import NewsCarousel from '../components/home/NewsCarousel';
import FeaturesCarousel from '../components/home/FeaturesCarousel';
import ReviewsCarousel from '../components/home/ReviewsCarousel';
import DigitalMagazineSection from '../components/home/DigitalMagazineSection';
import AppPromotion from '../components/app/AppPromotion';

export default function Home() {
  return (
    <div className="space-y-12">
      {/* Intro Card */}
      <section className="bg-gradient-to-r from-red-600 to-red-500 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold mb-4">Welcome to Glocal News</h1>
            <p className="text-xl mb-6">Your trusted source for hyperlocal to global news coverage</p>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
              <div className="bg-white/20 rounded-lg p-6">
                <div className="text-3xl font-bold">8+</div>
                <div className="text-sm">Languages</div>
              </div>
              <div className="bg-white/20 rounded-lg p-6">
                <div className="text-3xl font-bold">28</div>
                <div className="text-sm">States</div>
              </div>
              <div className="bg-white/20 rounded-lg p-6">
                <div className="text-3xl font-bold">500K+</div>
                <div className="text-sm">Daily Readers</div>
              </div>
              <div className="bg-white/20 rounded-lg p-6">
                <div className="text-3xl font-bold">1000+</div>
                <div className="text-sm">Journalists</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured News Carousel */}
      <section>
        <NewsCarousel />
      </section>

      {/* App Features */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-8">Why Choose Glocal News?</h2>
          <FeaturesCarousel />
        </div>
      </section>

      {/* Digital Magazine */}
      <section className="py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-8">Digital Magazine</h2>
          <DigitalMagazineSection />
        </div>
      </section>

      {/* User Reviews */}
      <section className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <ReviewsCarousel />
        </div>
      </section>

      {/* Download App Section */}
      <section className="bg-gradient-to-r from-red-600 to-red-500 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Download Our App</h2>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <span className="mr-2">✓</span>
                  Personalized news feed based on your interests
                </li>
                <li className="flex items-center">
                  <span className="mr-2">✓</span>
                  Offline reading support
                </li>
                <li className="flex items-center">
                  <span className="mr-2">✓</span>
                  Breaking news notifications
                </li>
                <li className="flex items-center">
                  <span className="mr-2">✓</span>
                  Video news and live TV
                </li>
                <li className="flex items-center">
                  <span className="mr-2">✓</span>
                  News in your language
                </li>
              </ul>
              <AppPromotion />
            </div>
            <div className="hidden md:block">
              <img 
                src="/images/app-preview.png" 
                alt="Glocal News App" 
                className="w-full max-w-md mx-auto"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Why Glocal News?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="bg-red-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl">🌍</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Hyperlocal Coverage</h3>
              <p className="text-gray-600">
                Get news from your locality in your preferred language
              </p>
            </div>
            <div className="text-center">
              <div className="bg-red-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl">✓</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Verified News</h3>
              <p className="text-gray-600">
                All news goes through our rigorous fact-checking process
              </p>
            </div>
            <div className="text-center">
              <div className="bg-red-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl">🎥</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Rich Media</h3>
              <p className="text-gray-600">
                Video news, live TV channels, and digital magazines
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Where to Use */}
      <section className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Available Everywhere</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <div className="text-4xl mb-4">📱</div>
              <h3 className="font-semibold mb-2">Mobile App</h3>
              <p className="text-gray-600">iOS and Android</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <div className="text-4xl mb-4">💻</div>
              <h3 className="font-semibold mb-2">Web Browser</h3>
              <p className="text-gray-600">Desktop & Mobile Web</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}