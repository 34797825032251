import { PlayCircleIcon } from '@heroicons/react/24/outline';

interface Channel {
  id: string;
  name: string;
  channelId: string;
  language: string;
  thumbnailUrl: string;
  description: string;
  category: string;
}

interface Props {
  channels: Channel[];
  language: string;
  searchQuery: string;
  onChannelSelect: (channelId: string) => void;
}

export default function ExternalLiveChannels({ channels, language, searchQuery, onChannelSelect }: Props) {
  const filteredChannels = channels.filter(channel => {
    const matchesLanguage = language === 'all' || channel.language.toLowerCase() === language;
    const matchesSearch = !searchQuery || 
      channel.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      channel.description.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesLanguage && matchesSearch;
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {filteredChannels.map((channel) => (
        <div
          key={channel.id}
          className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
        >
          <div className="relative">
            <img
              src={channel.thumbnailUrl}
              alt={channel.name}
              className="w-full h-48 object-cover"
            />
            <button
              onClick={() => onChannelSelect(channel.channelId)}
              className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity"
            >
              <PlayCircleIcon className="h-16 w-16 text-white" />
            </button>
          </div>
          <div className="p-4">
            <h3 className="font-semibold mb-2">{channel.name}</h3>
            <p className="text-sm text-gray-600 mb-2">{channel.description}</p>
            <div className="flex items-center justify-between">
              <span className="text-sm text-red-600 capitalize">{channel.language}</span>
              <span className="text-sm text-gray-500">{channel.category}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}