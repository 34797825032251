import { Link } from 'react-router-dom';
import { NewsArticle } from '../../types/news';

interface NewsCardProps {
  article: NewsArticle;
}

export default function NewsCard({ article }: NewsCardProps) {
  return (
    <Link to={`/news/${article.id}`} className="block">
      <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
        <img
          src={article.imageUrl}
          alt={article.title}
          className="w-full h-48 object-cover"
        />
        <div className="p-4">
          <div className="flex items-center space-x-2 mb-2">
            <span className="text-xs font-medium text-red-600">{article.category}</span>
            <span className="text-xs text-gray-500">
              {new Date(article.publishedAt).toLocaleDateString()}
            </span>
          </div>
          <h3 className="text-lg font-semibold mb-2 line-clamp-2">{article.title}</h3>
          <p className="text-gray-600 text-sm line-clamp-2">{article.summary}</p>
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center">
              <img
                src={`https://ui-avatars.com/api/?name=${article.authorName}`}
                alt={article.authorName}
                className="w-6 h-6 rounded-full mr-2"
              />
              <span className="text-sm text-gray-600">{article.authorName}</span>
            </div>
            <div className="flex items-center space-x-4 text-sm text-gray-500">
              <span>{article.views} views</span>
              <span>{article.likes} likes</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}