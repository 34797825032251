import { useAuth } from '../../../hooks/useAuth';
import Logo from '../../../components/layout/Logo';

export default function AdminHeader() {
  const { signOut } = useAuth();

  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <Logo />
            <span className="ml-4 text-lg font-semibold text-gray-900">Admin Portal</span>
          </div>
          <button
            onClick={signOut}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
          >
            Sign Out
          </button>
        </div>
      </div>
    </header>
  );
}