export const sampleLiveChannels = [
  // Telugu News Channels
  {
    id: 'tv9telugu',
    name: 'TV9 Telugu',
    channelId: 'UCPXTXMecYqnRKNdqdVOGSFg',
    language: 'telugu',
    thumbnailUrl: 'https://yt3.googleusercontent.com/ytc/AIf8zZTDkfjhwITD-4_48bQeNy7tPU9-Nf3IXPt6Qj5fhA=s176-c-k-c0x00ffffff-no-rj',
    description: 'TV9 Telugu Live News Channel',
    category: 'News'
  },
  {
    id: 'ntvtelugu',
    name: 'NTV Telugu',
    channelId: 'UCumtYpCY26F6Jr3satUgMvA',
    language: 'telugu',
    thumbnailUrl: 'https://yt3.googleusercontent.com/ytc/AIf8zZQf6oNBxTgeE8GtXgIkQS7CYAqrPyoGlQYyZYjw=s176-c-k-c0x00ffffff-no-rj',
    description: 'NTV Telugu Live News Channel',
    category: 'News'
  },
  {
    id: 'sakshi',
    name: 'Sakshi TV',
    channelId: 'UCQ_FATLW83q-4xJ2fsi8qAw',
    language: 'telugu',
    thumbnailUrl: 'https://yt3.googleusercontent.com/ytc/AIf8zZTuZRNQFv3rsnxYTrZQV3GqQdHVG8qQJXKWOBuY=s176-c-k-c0x00ffffff-no-rj',
    description: 'Sakshi TV Live News Channel',
    category: 'News'
  },

  // Kannada News Channels
  {
    id: 'tv9kannada',
    name: 'TV9 Kannada',
    channelId: 'UChiwdhYtcNhqXD3acXe9FQQ',
    language: 'kannada',
    thumbnailUrl: 'https://yt3.googleusercontent.com/ytc/AIf8zZQEQJhK4--Dl6Z_kgxkXvWcjFenSXY8_yBXmjJh=s176-c-k-c0x00ffffff-no-rj',
    description: 'TV9 Kannada Live News Channel',
    category: 'News'
  },
  {
    id: 'publictvkannada',
    name: 'Public TV',
    channelId: 'UChT-gOK4J3WGmqmDX4QbwxQ',
    language: 'kannada',
    thumbnailUrl: 'https://yt3.googleusercontent.com/ytc/AIf8zZQHPBXPe3tQZKHBPkwqPkJqBqEVJ7-5JzbXgyHc=s176-c-k-c0x00ffffff-no-rj',
    description: 'Public TV Kannada Live News Channel',
    category: 'News'
  },
  {
    id: 'newsfirst',
    name: 'News First',
    channelId: 'UCF8c1FHt9DqN0_ht_r1hJYQ',
    language: 'kannada',
    thumbnailUrl: 'https://yt3.googleusercontent.com/ytc/AIf8zZTKVz3UVPGGHzjgOgZYFH-UHRyNzEQQw7yKGHQh=s176-c-k-c0x00ffffff-no-rj',
    description: 'News First Kannada Live News Channel',
    category: 'News'
  },

  // Tamil News Channels
  {
    id: 'polimernews',
    name: 'Polimer News',
    channelId: 'UC8Z-VjXBtDJTvq6aqkIskPg',
    language: 'tamil',
    thumbnailUrl: 'https://yt3.googleusercontent.com/ytc/AIf8zZR3_elJhHqYnGZnwO-QYA2Zox_EXbxBmfPr6Tc-=s176-c-k-c0x00ffffff-no-rj',
    description: 'Polimer News Live Tamil News Channel',
    category: 'News'
  },
  {
    id: 'thanthitv',
    name: 'Thanthi TV',
    channelId: 'UC-JFyL0zDFOsPMpuWu39rPA',
    language: 'tamil',
    thumbnailUrl: 'https://yt3.googleusercontent.com/ytc/AIf8zZQV4HgWaQJmJ1DqhmFts1yKT4k-9yNQqTXf8kKt=s176-c-k-c0x00ffffff-no-rj',
    description: 'Thanthi TV Live News Channel',
    category: 'News'
  },
  {
    id: 'newsj',
    name: 'News J',
    channelId: 'UCsfh2Zb7-m4qzT8jLhK_Fzw',
    language: 'tamil',
    thumbnailUrl: 'https://yt3.googleusercontent.com/ytc/AIf8zZRUbTuVXWHkZqyPnFqBGFzOpxDuZEI9nw-g3g=s176-c-k-c0x00ffffff-no-rj',
    description: 'News J Tamil Live News Channel',
    category: 'News'
  }
];